import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  AddressModelKeys,
  AssociationKeys,
  ATTENDEE_TYPE_LOOKUP,
  ATTENDEE_WIZARD_STATE_LOOKUP,
  AttendeeKeys,
  AttendeeType,
  BaseModelKeys,
  BUSINESS_PERSONAL_TYPE_LOOKUP,
  Conference,
  CONFERENCE_YEARS_LIST,
  ConferenceKeys,
  Constants,
  DietaryConsiderationKeys,
  EmailAddressKeys,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  EmailTemplates,
  INVITEE_AGE_GROUP_LOOKUP,
  INVITEE_OUTCOME_STATUS_LOOKUP,
  INVITEE_STATUS_LOOKUP,
  InviteeStatus,
  LookupKeys,
  PHONE_NUMBER_TYPE_LOOKUP,
  QUALIFIED_AS_LOOKUP,
  Registrant,
  RegistrantModelKeys,
  RegistrantWizardStateKeys,
  REGISTRATION_TYPE_LOOKUP,
  TShirtSizesKeys,
  YES_NO_TYPE,
} from 'ag-common-lib/public-api';
import { ModalWindowComponent } from 'ag-common-svc/lib/components/modal-window/modal-window.component';
import { AgencyService, LookupsService } from 'ag-common-svc/public-api';
import { DxDataGridComponent } from 'devextreme-angular';
import { BehaviorSubject, firstValueFrom, map, shareReplay } from 'rxjs';
import { AttendeesExportService, RegistrantsExportType } from '../../../attendees-export.service';
import { AttendeesListService } from '../../../attendees-list.service';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { ItemClickEvent } from 'devextreme/ui/drop_down_button';
import { AttendeeDetailsModalComponent } from '../attendee-details-modal/attendee-details-modal.component';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import { AddAttendeesModalComponent } from './add-attendees-modal/add-attendees-modal.component';
import { CloudFunctionsService } from 'ag-common-svc/lib/services/cloud-functions.service';
import { RowRemovingEvent } from 'devextreme/ui/data_grid';

import { AttendeesEmailsService } from '../../../attendees-emails.service';
import { ConferenceRegistrationEmailCampaignService } from 'ag-common-svc/lib/services/email-campaigns/conference-registration-email-campaign.service';
import {
  NotificationOptInGroup,
  NotificationOptInGroupKeys,
  NotificationOptInGroupType,
  PushNotificationCampaignKeys,
} from 'ag-common-lib/lib';
import { NotificationSchedulerEditModalComponent } from 'ag-common-svc/lib/components/administration/notifications-hub/notifications-scheduler/notification-scheduler-edit-modal/notification-scheduler-edit-modal.component';
import { convertFilter } from 'ag-common-svc/lib/utils/filter-builder-converter.utils';
import { ConferenceAttendeeGridFieldsService } from 'ag-common-svc/lib/services/agent-grid-fields/conference-attendee-grid-fields.service';
import { Attendee } from 'ag-common-svc/lib/utils/attendees';
import { EventNameTitlePipe } from 'ag-common-svc/shared/pipes/conference-registration/event-name-title.pipe';
import { custom } from 'devextreme/ui/dialog';

@UntilDestroy()
@Component({
  selector: 'ag-crm-attendees-list-modal',
  templateUrl: './attendees-list-modal.component.html',
  styleUrls: ['./attendees-list-modal.component.scss'],
  providers: [
    AttendeesListService,
    ConferenceRegistrationEmailCampaignService,
    AttendeesEmailsService,
    EventNameTitlePipe,
  ],
})
export class AttendeesListModalComponent {
  @HostBinding('class') className = 'attendees-list-modal';
  @ViewChild('attendeeDetailsModalRef', { static: true }) attendeeDetailsModalComponent: AttendeeDetailsModalComponent;
  @ViewChild('attendeesListModalRef', { static: true }) attendeesListModalComponent: ModalWindowComponent;
  @ViewChild('attendeesReportYears', { static: true }) attendeesReportYearsModal: ModalWindowComponent;
  @ViewChild('registrantGrid', { static: false }) registrantGrid: DxDataGridComponent;
  @ViewChild('addAttendeesModalRef', { static: false }) addAttendeesModalComponent: AddAttendeesModalComponent;
  @ViewChild('notificationSchedulerEditModalRef', { static: false })
  protected notificationSchedulerEditModalComponent: NotificationSchedulerEditModalComponent;

  @Input() inProgress = false;
  @Output() registrantsChange = new EventEmitter<Registrant[]>();

  attendees: Promise<Attendee[]>;
  eventNameTitle: string;

  agentEmail$ = new BehaviorSubject<string>(null);
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;
  emailTypeLookup$ = this.lookupsService.emailTypeLookup$;
  tShortSizesLookup$ = this.lookupsService.tShortSizesLookup$;
  associationTypeLookup$ = this.lookupsService.associationTypeLookup$;
  prefixesLookup$ = this.lookupsService.prefixesLookup$;
  suffixesLookup$ = this.lookupsService.suffixesLookup$;
  genderTypeLookup$ = this.lookupsService.gendersLookup$;
  dietaryConsiderationTypesLookup$ = this.lookupsService.dietaryConsiderationTypesLookup$;

  agencies$ = this.agencyService.getList().pipe(shareReplay(1));
  attendees$ = this.attendeesListService.attendees$.pipe(
    map(
      (data = []) =>
        new DataSource({
          paginate: true,
          store: new ArrayStore({ key: AttendeeKeys.dbId, data }),
        }),
    ),
  );
  conferenceDbId$ = this.attendeesListService.conferenceDbId$;

  attendeesReportYearsValue: Array<Date | number | string> = [...CONFERENCE_YEARS_LIST];

  exportInProgress$ = this.exportService.exportInProgress$;

  protected readonly phoneMask = Constants.PHONE_MASK;
  protected readonly AttendeeKeys = AttendeeKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly AttendeeType = AttendeeType;
  protected readonly AssociationKeys = AssociationKeys;
  protected readonly AddressModelKeys = AddressModelKeys;
  protected readonly EmailAddressKeys = EmailAddressKeys;
  protected readonly TShirtSizesKeys = TShirtSizesKeys;
  protected readonly DietaryConsiderationKeys = DietaryConsiderationKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly yesNoType = YES_NO_TYPE;
  protected readonly BUSINESS_PERSONAL_TYPE_LOOKUP = BUSINESS_PERSONAL_TYPE_LOOKUP;
  protected readonly PHONE_NUMBER_TYPE_LOOKUP = PHONE_NUMBER_TYPE_LOOKUP;
  protected readonly INVITEE_AGE_GROUP_LOOKUP = INVITEE_AGE_GROUP_LOOKUP;
  protected readonly INVITEE_STATUS_LOOKUP = INVITEE_STATUS_LOOKUP;
  protected readonly INVITEE_OUTCOME_STATUS_LOOKUP = INVITEE_OUTCOME_STATUS_LOOKUP;
  protected readonly emptyMessage: string = 'No Attendees Currently Exist';
  protected readonly dateFormat: string = Constants.DISPLAY_DATE_FORMAT;
  protected readonly InviteeStatus = InviteeStatus;
  protected readonly attendeeTypeLookup = ATTENDEE_TYPE_LOOKUP;
  protected readonly qualifiedAsLookup = QUALIFIED_AS_LOOKUP;
  protected readonly registrationTypeLookup = REGISTRATION_TYPE_LOOKUP;
  protected readonly attendeeWizardStateLookup = ATTENDEE_WIZARD_STATE_LOOKUP;
  protected readonly YES_NO_TYPE_LOOKUP = [YES_NO_TYPE.Yes, YES_NO_TYPE.No];
  protected readonly CONFERENCE_YEARS_LIST = CONFERENCE_YEARS_LIST;
  protected conferenceEmailTemplates$ = this.attendeesListService.conference$.pipe(
    map(conference => {
      const isShortEmailTemplate =
        conference?.[ConferenceKeys.summaryEmailTemplate] ===
        EmailTemplates.notifyEmailConferenceRegistrationShortSummary;
      const isExcursionStepExcluded = !(
        conference?.[ConferenceKeys.excursionsActive] && conference?.[ConferenceKeys.excursionsEnabled]
      );
      const emailTemplates = [
        {
          [LookupKeys.value]: 'scheduleNotification',
          [LookupKeys.description]: 'Schedule Notification',
        },
      ];

      this.attendeesEmailsService.emailTemplates.filter(template => {
        switch (template.value) {
          case EmailTemplates.notifyEmailConferenceRegistrationSummary:
            emailTemplates.push({
              [LookupKeys.value]:
                conference?.[ConferenceKeys.summaryEmailTemplate] ??
                EmailTemplates.notifyEmailConferenceRegistrationSummary,
              [LookupKeys.description]: 'Registration Summary',
            });
            break;

          case EmailTemplates.notifyEmailKnowBeforeYouGoToConference:
            if (isShortEmailTemplate) {
              return;
            }
            emailTemplates.push(template);
            break;

          case EmailTemplates.notifyEmailInviteesActivitiesFirstDay:
          case EmailTemplates.notifyEmailInviteesActivitiesSecondDay:
            if (isExcursionStepExcluded) {
              return;
            }
            emailTemplates.push(template);
            break;

          default:
            emailTemplates.push(template);
            break;
        }
      });

      return emailTemplates;
    }),
    shareReplay(1),
  );

  constructor(
    private readonly lookupsService: LookupsService,
    private agencyService: AgencyService,
    public exportService: AttendeesExportService,
    private attendeesListService: AttendeesListService,
    private cloudFunctionsService: CloudFunctionsService,
    private attendeesEmailsService: AttendeesEmailsService,
    private conferenceAttendeeGridFieldsService: ConferenceAttendeeGridFieldsService,
    private eventNameTitlePipe: EventNameTitlePipe,
  ) {}

  showModal(data: Conference): void {
    this.attendeesListService.setConferenceDbId(data?.[BaseModelKeys.dbId]);
    const eventName = data?.[ConferenceKeys.eventName];
    const caption = 'Conference Attendees';

    this.eventNameTitle = this.eventNameTitlePipe.transform(eventName, caption + Constants.EVENT_NAME_TITLE, caption);
    this.attendeesListModalComponent?.showModal();
  }

  showAddAttendeesModal = async () => {
    const conferenceDbId = await firstValueFrom(this.conferenceDbId$);
    const attendees: Attendee[] = await firstValueFrom(this.attendeesListService.attendees$);

    this.addAttendeesModalComponent?.showModal(conferenceDbId, attendees);
  };

  editAttendee = data => {
    this.attendeeDetailsModalComponent.showModal(data);
  };

  async getCustomReport(e: ItemClickEvent) {
    if (e.itemData?.reportType === RegistrantsExportType.productionByQuarter) {
      this.attendeesReportYearsModal.showModal();
      return;
    }

    const conferenceDbId = await firstValueFrom(this.conferenceDbId$);
    const attendeeData = await firstValueFrom(this.attendeesListService.attendees$.pipe());
    await this.exportService.runCustomExport(conferenceDbId, e.itemData?.reportType, attendeeData);
  }

  async runByQuarterReport() {
    this.attendeesReportYearsModal.hideModal();
    const attendeeData = await firstValueFrom(this.attendeesListService.attendees$.pipe());

    const conferenceDbId = await firstValueFrom(this.conferenceDbId$);
    await this.exportService.runCustomExport(
      conferenceDbId,
      RegistrantsExportType.productionByQuarter,
      attendeeData,
      this.attendeesReportYearsValue,
    );
    this.attendeesReportYearsValue = [...CONFERENCE_YEARS_LIST];
  }

  protected onRowRemoving = async (data: Attendee) => {
    const dialog = custom({
      showTitle: false,
      messageHtml: 'Are you sure you want to delete this record?',
      buttons: [
        {
          text: 'Yes',
          onClick: () => {
            return true;
          },
        },
        {
          text: 'No',
          onClick: () => {
            return false;
          },
        },
      ],
    });

    const result = await dialog.show();

    if (!result) {
      return;
    }

    this.registrantGrid.instance.beginCustomLoading('Deleting...');

    const conferenceDbId = data?.[AttendeeKeys.conferenceDbId];
    const registrantDbId = data?.[BaseModelKeys.dbId];
    await this.cloudFunctionsService.deleteConferenceRegistration({ conferenceDbId, registrantDbId });
    this.registrantGrid.instance.endCustomLoading();
  };

  protected onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
      });
    });
  }

  checkIsAttendeeDetailsButtonVisible = ({ row: { data } }): boolean =>
    data?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee;

  checkIsWizardStateIsSubmitted = ({ row: { data } }): boolean =>
    (data?.[AttendeeKeys.attendeeType] === AttendeeType.Invitee &&
      data?.registrant?.[RegistrantModelKeys.wizardState]?.[RegistrantWizardStateKeys.isSubmitted]) ??
    false;

  protected sendToEmail = async (e: ItemClickEvent, attendee: Attendee) => {
    await this.attendeesEmailsService.sendEmails(e.itemData, [attendee]);
  };

  protected sendBulkEmails = async (e: ItemClickEvent): Promise<void> => {
    const dataSource = this.registrantGrid.instance.getDataSource();
    const filter = this.registrantGrid.instance.getCombinedFilter(true);
    const resultFilter: any[] = [[AttendeeKeys.attendeeType, '=', AttendeeType.Invitee]];

    if (e.itemData?.value === 'scheduleNotification') {
      const conferenceDbId = await firstValueFrom(this.conferenceDbId$);

      const optInGroup = Object.assign({}, new NotificationOptInGroup(NotificationOptInGroupType.registrant), {
        [NotificationOptInGroupKeys.payload]: { conferenceDbId },
        [NotificationOptInGroupKeys.filterExpression]: filter ? convertFilter(filter) : null,
      });

      this.notificationSchedulerEditModalComponent.showModal({
        [PushNotificationCampaignKeys.optInGroups]: [optInGroup],
      });
      return;
    }

    const data = await dataSource.store().load({ filter: resultFilter });

    if (!Array.isArray(data)) {
      // TODO
      return;
    }

    await this.attendeesEmailsService.sendEmails(e.itemData, data as Attendee[]);
  };

  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
